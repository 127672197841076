<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo">
                        <router-link to="/">
                            <img src="@/assets/images/logo/IconeLogo.png" class="navbar-logo" alt="logo" />
                        </router-link>
                    </li>
                    <li class="nav-item theme-text">
                        <router-link to="/" class="nav-link"> TimeSheet </router-link>
                    </li>
                </ul>

                <div class="navbar-item flex-row ms-md-auto">
                    <div class="dark-mode d-flex align-items-center">
                        <a v-if="$store.state.dark_mode == 'light'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('dark')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-sun"
                            >
                                <circle cx="12" cy="12" r="5"></circle>
                                <line x1="12" y1="1" x2="12" y2="3"></line>
                                <line x1="12" y1="21" x2="12" y2="23"></line>
                                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                <line x1="1" y1="12" x2="3" y2="12"></line>
                                <line x1="21" y1="12" x2="23" y2="12"></line>
                                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                            </svg>
                            <span class="ms-2">{{ $t('light') }}</span>
                        </a>
                        <a v-if="$store.state.dark_mode == 'dark'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('system')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-moon"
                            >
                                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                            </svg>
                            <span class="ms-2">{{ $t('dark') }}</span>
                        </a>
                        <a v-if="$store.state.dark_mode == 'system'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('light')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-airplay"
                            >
                                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                                <polygon points="12 15 17 21 7 21 12 15"></polygon>
                            </svg>
                            <span class="ms-2">{{ $t('system') }}</span>
                        </a>
                    </div>

                    <div class="dropdown nav-item language-dropdown btn-group d-none d-lg-flex">
                        <a
                            class="btn dropdown-toggle btn-icon-only nav-link"
                            :class="{
                                'text-primary': notifyState === 'granted',
                                'text-danger': notifyState === 'denied',
                            }"
                            @click="notifyPermission"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-bell"
                            >
                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                            </svg>
                        </a>
                    </div>

                    <div class="dropdown nav-item language-dropdown btn-group">
                        <a href="javascript:;" id="ddllang" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
                            <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)" class="flag-width" alt="flag" />
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="ddllang">
                            <perfect-scrollbar>
                                <li v-for="item in countryList" :key="item.code">
                                    <a href="javascript:;" class="dropdown-item d-flex align-items-center" :class="{ active: $i18n.locale === item.code }" @click.prevent="changeLanguage(item)">
                                        <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width" alt="" /> <span>{{ item.name }}</span>
                                    </a>
                                </li>
                            </perfect-scrollbar>
                        </ul>
                    </div>

                    <div class="dropdown nav-item user-profile-dropdown btn-group d-lg-none d-inline-flex" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <a href="javascript:;" id="ddluser" class="btn dropdown-toggle btn-icon-only user nav-link">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-menu"
                            >
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                        </a>
                    </div>

                    <div class="dropdown nav-item user-profile-dropdown btn-group d-none d-lg-inline-flex">
                        <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only user nav-link">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-menu"
                            >
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
                            <li role="presentation">
                                <router-link to="/perfil" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-user"
                                    >
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                        <circle cx="12" cy="7" r="4" />
                                    </svg>
                                    {{ $t('profile') }}
                                </router-link>
                            </li>
                            <li role="presentation">
                                <router-link to="" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalSupport">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-code"
                                    >
                                        <polyline points="16 18 22 12 16 6" />
                                        <polyline points="8 6 2 12 8 18" />
                                    </svg>
                                    {{ $t('support') }}
                                </router-link>
                            </li>
                            <li role="presentation">
                                <router-link to="/ordem-de-servico" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-airplay"
                                    >
                                        <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1" />
                                        <polygon points="12 15 17 21 7 21 12 15" />
                                    </svg>
                                    {{ $t('service order') }}
                                </router-link>
                            </li>
                            <li role="presentation" @click="logout">
                                <router-link to="" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-5linejoin="round"
                                        class="feather feather-log-out"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                        <polyline points="16 17 21 12 16 7"></polyline>
                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                    </svg>
                                    {{ $t('exit') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->

        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id="breadcrumb" class="vue-portal-target"></div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN TOPBAR  -->
        <div class="topbar-nav header navbar" role="banner">
            <nav class="topbar">
                <ul class="list-unstyled menu-categories justify-content-center" id="topAccordion">
                    <router-link to="/">
                        <li class="menu single-menu menuSingle">
                            <a href="javascript:;" class="dropdown-toggle autodroprown">
                                <div class="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-home"
                                    >
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                    </svg>
                                    <span>{{ $t('home') }} </span>
                                </div>
                            </a>
                        </li>
                    </router-link>

                    <router-link to="/master" v-if="masterView || responsavelView">
                        <li class="menu single-menu menuSingle">
                            <a href="javascript:;" class="dropdown-toggle autodroprown">
                                <div class="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-activity"
                                    >
                                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
                                    </svg>
                                    <span>{{ $t('dashboard bi') }} </span>
                                </div>
                            </a>
                        </li>
                    </router-link>

                    <router-link to="/atividades" v-if="!adminView && !responsavelView && !masterView">
                        <li class="menu single-menu">
                            <a href="javascript:;" class="dropdown-toggle">
                                <div class="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-clipboard"
                                    >
                                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                                        <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
                                    </svg>
                                    <span>{{ $t('activities') }}</span>
                                </div>
                            </a>
                        </li>
                    </router-link>

                    <li class="menu single-menu" v-if="adminView || responsavelView || masterView">
                        <a href="javascript:;" class="dropdown-toggle">
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-clipboard"
                                >
                                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
                                </svg>
                                <span>{{ $t('activities') }}</span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                        <ul class="collapse submenu list-unstyled">
                            <li>
                                <router-link to="/atividades">{{ $t('time clock') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/atividades/gerenciar">{{ $t('management') }}</router-link>
                            </li>
                            <li>
                                <!-- <router-link to="/atividades/lancamentos" v-if="adminView || masterView">{{ $t('record an activity') }}</router-link> -->
                                <router-link to="/atividades/lancamentos" v-if="adminView || masterView">{{ $t('Lançamentos') }}</router-link>
                            </li>
                        </ul>
                    </li>

                    <router-link to="/atividades/historico">
                        <li class="menu single-menu menuSingle">
                            <a href="javascript:;" class="dropdown-toggle">
                                <div class="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-clock"
                                    >
                                        <circle cx="12" cy="12" r="10" />
                                        <polyline points="12 6 12 12 16 14" />
                                    </svg>
                                    <span>{{ $t('history') }}</span>
                                </div>
                            </a>
                        </li>
                    </router-link>

                    <li class="menu single-menu" v-if="adminView || masterView">
                        <a href="javascript:;" class="dropdown-toggle">
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-users"
                                >
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                    <circle cx="9" cy="7" r="4" />
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                </svg>
                                <span>{{ $t('users') }}</span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                        <ul class="collapse submenu list-unstyled">
                            <li>
                                <router-link to="/usuarios/cadastrar">{{ $t('register') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/usuarios/listar">{{ $t('display') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/usuarios/cargos">{{ $t('roles') }}</router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="menu single-menu" v-if="adminView || masterView">
                        <a href="javascript:;" class="dropdown-toggle">
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-smile"
                                >
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M8 14s1.5 2 4 2 4-2 4-2" />
                                    <line x1="9" y1="9" x2="9.01" y2="9" />
                                    <line x1="15" y1="9" x2="15.01" y2="9" />
                                </svg>
                                <span>{{ $t('client') }}</span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                        <ul class="collapse submenu list-unstyled">
                            <li>
                                <router-link to="/clientes/Cadastrar">{{ $t('register') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/clientes/listar">{{ $t('display') }}</router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="menu single-menu" v-if="adminView || masterView || responsavelView">
                        <a href="javascript:;" class="dropdown-toggle">
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-table"
                                >
                                    <path d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18" />
                                </svg>
                                <span>{{ $t('report') }}</span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                        <ul class="collapse submenu list-unstyled">
                            <li>
                                <router-link to="/relatorios/historico-atividade">{{ $t('activity History') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/relatorios/historico-dia-dia">{{ $t('day-to-Day History') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/relatorios/horas-por-clientes">{{ $t('hours by Client') }}</router-link>
                            </li>
                            <li v-if="masterView">
                                <router-link to="/relatorios/relatorio-de-custo-por-cliente">{{ $t('cost per client') }}</router-link>
                            </li>
                            <li v-if="masterView">
                                <router-link to="/relatorios/relatorio-de-nota-para-o-cliente">{{ $t('Nota de Debito') }}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="menu single-menu" v-if="masterView || adminView">
                        <a href="javascript:;" class="dropdown-toggle">
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-airplay"
                                >
                                    <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1" />
                                    <polygon points="12 15 17 21 7 21 12 15" />
                                </svg>
                                <span>{{ $t('Ordem de Serviço') }}</span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                        <ul class="collapse submenu list-unstyled">
                            <li>
                                <router-link to="/ordem-de-servico-listar">{{ $t('manage os') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/ordem-de-servico-ti" v-if="masterView">{{ $t('Chamados TI') }}</router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="menu single-menu" v-if="decodeJWT()['setor'] == 1">
                        <router-link to="/ordem-de-servico-ti" class="dropdown-toggle autodroprown">
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-code"
                                >
                                    <polyline points="16 18 22 12 16 6" />
                                    <polyline points="8 6 2 12 8 18" />
                                </svg>
                                <span>Os de suporte</span>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
        <!--  END TOPBAR  -->
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modalSupport" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('support') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="modalOption === 'bug'">
                        <form @submit.prevent="postBug">
                            <h4>{{ $t('report bug or inconsistency') }}</h4>
                            <div class="row mb-5">
                                <div class="col-lg-4 col-12">
                                    <label for="nomeUsuario" class="form-label">{{ $t('user') }}</label>
                                    <input id="nomeUsuario" class="form-control" type="text" disabled v-model="supportObject.nome" />
                                </div>
                                <div class="col-lg-4 col-12">
                                    <label for="setorUsuario" class="form-label">{{ $t('sector') }}</label>
                                    <input id="setorUsuario" class="form-control" type="text" disabled v-model="supportObject.setor" />
                                </div>
                                <div class="col-lg-4 col-12">
                                    <label for="localChamado" class="form-label">{{ $t('location of the call') }}</label>
                                    <input id="localChamado" class="form-control" type="text" disabled v-model="supportObject.localDoChamado" />
                                </div>
                            </div>

                            <div class="mb-3">
                                <textarea
                                    required
                                    class="form-control"
                                    minlength="10"
                                    maxlength="2000"
                                    rows="4"
                                    :placeholder="$t('describe the problem encountered')"
                                    v-model="supportObject.descricao"
                                ></textarea>
                                <small> {{ tamanhoDotexto(supportObject.descricao) }} / 2000</small>
                            </div>

                            <div class="row">
                                <div class="mb-5 col-lg-6 col-12">
                                    <label
                                        ><small>{{ $t('optional') }}</small></label
                                    >
                                    <input class="form-control" type="file" @change="(event) => handleFileUpload(event)" />
                                </div>
                                <div class="mb-5 col-lg-6 col-12">
                                    <label>{{ $t('urgency') }}</label>
                                    <select class="form-control" v-model="supportObject.urgenciaId">
                                        <option v-for="urgencia in urgencias" :key="id" :value="urgencia.id">{{ urgencia.nome }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="text-center">
                                <button type="submit" class="btn btn-primary fs-5 w-50">{{ $t('submit') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="btnModalSupport" class="btn btn-dark" data-bs-dismiss="modal">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
    <loaderComponent v-if="loader" />
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import decodeJWT from '@/composables/decodeJWT';
    import notifyImg from '@/assets/images/logo/logo.png';
    import apiClient from '@/api';
    import enumSetores from '@/composables/enum/enumSetores';
    import showAlert from '@/composables/showAlert';
    import loaderComponent from '../loaderComponent.vue';
    import handleApiResponse from '@/composables/handleApiResponse';

    const store = useStore();
    const route = useRoute();
    const i18n = useI18n();
    const selectedLang = ref(null);
    const countryList = ref(store.state.countryList);
    const role = ref('');
    const responsaveisPorSetor = ref([
        'ResponsavelFinanceiro',
        'ResponsavelFiscal',
        'ResponsavelContabil',
        'ResponsavelDepartamento',
        'ResponsavelTi',
        'ResponsavelAuditoria',
        'ResponsavelAdministrativo',
        'ResponsavelDepartamento',
        'ResponsavelMarketing',
        'ResponsavelComercial',
        'ResponsavelProcessos',
        'ResponsavelSocietarios',
    ]);
    const modalOption = ref('bug');
    // const usuario = ref(decodeJWT());
    const notifyState = ref(null);
    const setores = enumSetores();
    const urgencias = ref({});

    const adminView = ref(false);
    const masterView = ref(false);
    const responsavelView = ref(false);

    let loader = ref(false);

    /** Objeto do support
     */
    const supportObject = ref({
        usuarioId: decodeJWT()['userId'],
        nome: decodeJWT()['Nome'],
        setor: setores.getSector(decodeJWT()['setor']),
        localDoChamado: route.fullPath,
        descricao: null,
        urgenciaId: 1,

        imagemBase64: null,
    });
    /** Get das Urgencias do banco de dados
     */
    const getUrgencias = async () => {
        try {
            const response = await apiClient.get(`OrdemDeServico/GetUrgencias`);
            urgencias.value = response.data.objeto;
            handleApiResponse(response);
        } catch (error) {
            handleApiResponse(error, true);
        }
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    const notifyPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
        } else {
            notifyState.value = Notification.permission;
            new Notification('Notificação Ativa', {
                body: 'As Notificações do TimeSheet estão Ativas !',
                icon: notifyImg,
            });
        }
    };

    const postBug = async () => {
        loader.value = true;
        let objetoBug = { ...supportObject.value };
        objetoBug.setor = parseInt(decodeJWT()['setor']);

        try {
            const response = await apiClient.post(`Suporte?usuarioCadastrouId=${decodeJWT()['userId']}`, objetoBug);

            if (response.status === 201) {
                loader.value = false;
                Swal.fire({
                    icon: 'success',
                    title: 'Chamado aberto!',
                    text: 'O chamado foi aberto com sucesso.',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                }).then(() => {
                    const modalBtn = document.querySelector('#btnModalSupport');
                    if (modalBtn) {
                        modalBtn.click();
                    } else {
                        // console.error("Botão com ID 'btnModalSupport' não foi encontrado.");
                    }
                });
            } else {
                showAlert('Erro, verifique o Formulário!', 'error');
            }
        } catch (error) {
            showAlert('Erro, verifique o Formulário!', 'error');
        }
        loader.value = false;
    };

    const tamanhoDotexto = (text) => {
        if (text) {
            return text.length;
        } else {
            return 0;
        }
    };

    watch(
        () => route.fullPath,
        (newRoute) => {
            supportObject.value.localDoChamado = newRoute;
        }
    );

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                supportObject.value.imagemBase64 = base64String;
            };

            reader.onerror = (error) => {
                // console.error('Erro ao converter o arquivo para base64:', error);
            };

            reader.readAsDataURL(file);
        }
    };

    /** As Funções abaixo pertencer ao template
     */
    onMounted(() => {
        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();
        /** Os items abaixo não pertencem ao teamplate
         */
        getUrgencias();
        if (localStorage.getItem('token') && decodeJWT()['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']) {
            role.value = decodeJWT()['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            setViewByRoles();
        }
        notifyState.value = Notification.permission;
    });

    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };

    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);

        i18n.locale.value = item.code;
    };

    /* Função para Definir as View do sistema
     * Baseado em roles
     */

    const setViewByRoles = () => {
        if (typeof role.value == 'string') {
            role.value = [role.value];
        }

        if (role.value.includes('Admin')) {
            adminView.value = true;
        }

        if (role.value.includes('Master')) {
            masterView.value = true;
        }

        if (role.value.some((r) => responsaveisPorSetor.value.includes(r))) {
            responsavelView.value = true;
        }
    };
</script>
