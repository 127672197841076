<template>
    <div class="layout-px-spacing dash_1">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page"><span>Home</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="row layout-top-spacing align-items-stretch">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-revenue text-white p-4 h-100">
                    <span>Bem-Vindo,</span>
                    <br />
                    <h2 class="text-primary fs-5">{{ decodeJWT()['Nome'] }}</h2>

                    <p class="fs-6">O projeto está em crescimento, e ajustes ainda virão. Se encontrar problemas ou tiver sugestões, entre em contato com o TI. Estamos aqui para ajudar!</p>
                </div>
            </div>

            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="statbox panel box box-shadow h-100">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex justify-content-between align-content-center">
                                <h4>Atividades do Dia de Hoje</h4>
                                <svg
                                    data-bs-toggle="popover"
                                    data-bs-trigger="hover"
                                    data-bs-content="Nesta tabela você pode visualizar as atividades cadastradas no dia de hoje, assim com o tempo previsto e o tempo gasto para  concluí-las."
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-help-circle"
                                >
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                    <line x1="12" y1="17" x2="12.01" y2="17" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="table-responsive" style="max-height: 30vh; overflow-y: auto" v-if="atividadesHistorico.length > 0">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Atividade</th>
                                        <th>Cliente</th>
                                        <th class="text-center">Tempo estimado</th>
                                        <th class="text-center">Tempo total</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="atividade in atividadesHistorico" :key="atividade.id">
                                        <td>{{ atividade.nome }}</td>
                                        <td>{{ atividade.cliente?.nomeFantasia }}</td>
                                        <td class="text-center">{{ atividade.tempoEstimado ? atividade.tempoEstimado.slice(0, 8) : 'Não Informado' }}</td>
                                        <td class="text-center">{{ atividade.tempoTotal ? atividade.tempoTotal.slice(0, 8) : 'Atividade não finalizada' }}</td>
                                    </tr>
                                </tbody>

                                <tfoot :class="store.state.is_dark_mode ? 'bg-dark' : 'bg-light'" style="position: sticky; bottom: 0px; z-index: 2">
                                    <tr>
                                        <th scope="row" colspan="2">Total</th>
                                        <td class="text-center text-decoration-underline" style="text-underline-offset: 5px">{{ totalEstimadas ? totalEstimadas.slice(0, 8) : 'indisponível' }}</td>
                                        <td class="text-center text-decoration-underline" style="text-underline-offset: 5px">{{ totalTrabalhadas ? totalTrabalhadas.slice(0, 8) : 'indisponível' }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="card p-4" v-else>Nem uma Atividade registrada Hoje</div>
                    </div>
                </div>
            </div>

            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="statbox panel box box-shadow h-100">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex justify-content-between align-content-center">
                                <h4>Gráfico da Semana</h4>
                                <svg
                                    data-bs-toggle="popover"
                                    data-bs-trigger="hover"
                                    data-bs-content="Este gráfico apresenta um resumo das suas horas trabalhadas ao longo da semana. Você pode visualizar tanto as horas cumpridas quanto as horas extras realizadas ou as horas que ainda faltam para completar sua carga horária semanal."
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-help-circle"
                                >
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                    <line x1="12" y1="17" x2="12.01" y2="17" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <apexchart v-if="options_4" height="350" type="bar" :options="options_4" :series="series_4"></apexchart>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="statbox panel box box-shadow h-100">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex justify-content-between align-content-center">
                                <h4>Carga Horária</h4>
                                <svg
                                    data-bs-toggle="popover"
                                    data-bs-trigger="hover"
                                    data-bs-content="Este gráfio mostra o seu progresso  durante a semana"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-help-circle"
                                >
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                    <line x1="12" y1="17" x2="12.01" y2="17" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <apexchart v-if="series_7.length" height="350" type="donut" :options="options_7" :series="series_7" @dataPointSelection="findClientId"> </apexchart>
                        <div class="card p-4 h-100 text-center" v-else>Atividades não registradas ainda</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalHistAtividades" tabindex="-1" aria-labelledby="modalHistAtividadesLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalHistAtividadesLabel">Atividades prestadas</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-striped" v-if="modalData.length > 0">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Cliente</th>
                                        <th>Início</th>
                                        <th>Fim</th>
                                        <th>Tempo Total</th>
                                        <th>Tempo Estimado</th>
                                        <th>Descrição</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, index) in modalData" :key="index">
                                        <td>{{ data.nome }}</td>
                                        <td>{{ data.cliente?.nomeFantasia || 'N/A' }}</td>
                                        <td>{{ new Date(data.inicio).toLocaleDateString('pt-br') }}</td>
                                        <td>{{ new Date(data.fim).toLocaleDateString('pt-br') }}</td>
                                        <td>{{ data.tempoTotal.slice(0, 8) || 'Não Informado' }}</td>
                                        <td>{{ data.tempoEstimado.slice(0, 8) || 'Não Informado' }}</td>
                                        <td>{{ data.descricao || 'N/A' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <pre>{{ a }}</pre>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>

    <loaderComponent v-if="loader" />
</template>

<script setup>
    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'Home Funcionário' });

    import '@/assets/sass/widgets/widgets.scss';
    import decodeJWT from '@/composables/decodeJWT';
    import { computed, reactive, ref } from 'vue';
    import '@/assets/sass/scrollspyNav.scss';
    import apexchart from 'vue3-apexcharts';
    import { useStore } from 'vuex';
    import { onMounted } from 'vue';
    import { Modal } from 'bootstrap';

    import loaderComponent from '@/components/loaderComponent.vue';
    import handleApiResponse from '@/composables/handleApiResponse';
    import apiClient from '@/api';

    const store = useStore();
    const jwt = reactive(decodeJWT());
    const atividadesHistorico = ref({});
    const graficoDaSemana = ref({});
    const cargaHoraria = ref({});

    let loader = ref(false);
    let modal = ref(null);
    let modalData = ref({});
    let totalEstimadas = ref(0);
    let totalTrabalhadas = ref(0);

    /* Gráfico da Carga Horária
     */
    const labels_7 = ref([]);
    const series_7 = ref([1, 2, 3]);

    const options_7 = computed(() => {
        const is_dark = store.state.is_dark_mode;
        return {
            chart: { toolbar: { show: false } },
            labels: labels_7.value,
            stroke: { colors: is_dark ? '#191e3a' : 'transparent' },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: { width: 200 },
                        legend: { position: 'bottom' },
                    },
                },
            ],
            legend: { show: false },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                showAlways: true, // Sempre exibe o totalizador
                                label: 'Total',
                                color: '#00e274',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                formatter: function (w) {
                                    const totalMinutos = w.globals.series.reduce((a, b) => a + b, 0);
                                    return formatarMinutosParaTempo(totalMinutos);
                                },
                            },
                        },
                    },
                },
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (value) {
                        return formatarMinutosParaTempo(value);
                    },
                    title: {
                        formatter: function (seriesName) {
                            return seriesName;
                        },
                    },
                },
            },
        };
    });

    /* Gráfico da Semana
     */
    const series_4 = ref([
        { name: 'Horas Trabalhadas', data: [0, 0, 0, 0, 0] },
        { name: 'Horas Excedentes', data: [0, 0, 0, 0, 0] },
        { name: 'Carga horária', data: [8, 8, 8, 8, 8] },
    ]);

    const options_4 = computed(() => {
        const is_dark = store.state.is_dark_mode;
        return {
            chart: { stacked: true, toolbar: { show: false } },
            responsive: [{ breakpoint: 480, options: { legend: { offsetX: -10, offsetY: 5 } } }],
            plotOptions: { bar: { horizontal: false } },
            xaxis: {
                type: 'category',
                categories: ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'],
            },
            yaxis: {
                labels: {
                    formatter: (val) => formatarMinutosParaTempo(val), // Exibe hh:mm no eixo Y
                },
            },
            grid: { borderColor: is_dark ? '#191e3a' : '#e0e6ed' },
            fill: { opacity: 1 },
            legend: { position: 'bottom', offsetY: 10 },
            tooltip: {
                theme: is_dark ? 'dark' : 'light',
                y: {
                    formatter: (val) => formatarMinutosParaTempo(val), // Exibe hh:mm no tooltip
                },
            },
            dataLabels: {
                enabled: true,
                formatter: (val) => formatarMinutosParaTempo(val), // Exibe hh:mm diretamente nas barras
                style: {
                    fontSize: '12px',
                    colors: ['#000'], // Ajuste de cor opcional para contraste
                },
            },
            colors: ['#00e274', '#e7515a', '#191e3a70'],
        };
    });

    const formatarMinutosParaTempo = (minutos) => {
        const horas = Math.floor(minutos / 60);
        const min = minutos % 60;
        return `${String(horas).padStart(2, '0')}:${String(min).padStart(2, '0')}`;
    };

    const getAtividadesHistorico = async (pageNumber, pageSize) => {
        const data = new Date().toISOString().split('T')[0];
        let response = null;

        loader.value = true;
        try {
            response = await apiClient.get(`Historico/${decodeJWT()['userId']}/BuscarHistorico?pageNumber=${pageNumber}&pageSize=${pageSize}&inicio=${data}&fim=${data}`);

            handleApiResponse(response);
        } catch (error) {
            handleApiResponse(error);
            return;
        } finally {
            loader.value = false;
        }

        if (!response.data.sucesso) {
            handleApiResponse(response);
            loader.value = false;
            return;
        }

        atividadesHistorico.value = response.data.objeto;

        const timeToSeconds = (time) => {
            if (!time) return 0;
            time = time.slice(0, 8);
            const [hh, mm, ss] = time.split(':').map(Number);
            return (hh || 0) * 3600 + (mm || 0) * 60 + (ss || 0);
        };

        const secondsToTime = (seconds) => {
            const hh = Math.floor(seconds / 3600)
                .toString()
                .padStart(2, '0');
            const mm = Math.floor((seconds % 3600) / 60)
                .toString()
                .padStart(2, '0');
            const ss = (seconds % 60).toString().padStart(2, '0');
            return `${hh}:${mm}:${ss}`;
        };

        const totais = atividadesHistorico.value.reduce(
            (acc, atividade) => {
                acc.tempoEstimado += timeToSeconds(atividade.tempoEstimado?.slice(0, 8));
                acc.tempoTotal += timeToSeconds(atividade.tempoTotal?.slice(0, 8));
                return acc;
            },
            { tempoEstimado: 0, tempoTotal: 0 }
        );

        totalEstimadas.value = secondsToTime(totais.tempoEstimado);
        totalTrabalhadas.value = secondsToTime(totais.tempoTotal);
    };

    /* Função do Template para Usar os PopOvers
     */
    const initPopover = () => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        popoverTriggerList.map(function (popoverTriggerEl) {
            return new window.bootstrap.Popover(popoverTriggerEl);
        });
    };

    const getDashboardUsuarioGraficoDaSemana = async () => {
        loader.value = true;
        let response = null;

        try {
            response = await apiClient.get(`Relatorio/DashboardUsuarioGraficoDaSemana?userId=${jwt.userId}`);
        } catch (error) {
            handleApiResponse(response, true);
            return;
        } finally {
            loader.value = false;
        }

        if (!response.data.sucesso) {
            handleApiResponse(response, true);
            return;
        }

        graficoDaSemana.value = response.data.objeto;

        PopularDadosGraficoDaSemana();
    };

    const converterTempoParaMinutos = (tempo) => {
        const [horas, minutos] = tempo.split(':').map(Number);
        return horas * 60 + minutos;
    };

    const PopularDadosGraficoDaSemana = () => {
        series_4.value[0].data = [];
        series_4.value[1].data = [];
        series_4.value[2].data = [];

        graficoDaSemana.value.forEach((value) => {
            series_4.value[0].data.push(converterTempoParaMinutos(value.horasTrabalhadas));
            series_4.value[1].data.push(converterTempoParaMinutos(value.horasExcedentes));
            series_4.value[2].data.push(converterTempoParaMinutos(value.cargaHoraria));
        });
    };

    const getCargaHoraria = async () => {
        loader.value = true;
        let response = null;

        try {
            response = await apiClient.get(`Relatorio/DashboardUsuarioCargaHorariaSemanalEmpresas?userId=${jwt.userId}`);
        } catch (error) {
            handleApiResponse(response, true);
            return;
        } finally {
            loader.value = false;
        }

        if (!response.data.sucesso) {
            handleApiResponse(response, true);
            return;
        }

        cargaHoraria.value = response.data.objeto;

        PopularDadosGraficoCargaHoraria();
    };

    const PopularDadosGraficoCargaHoraria = () => {
        labels_7.value = cargaHoraria.value.map((value) => value.nome);
        series_7.value = cargaHoraria.value.map((value) => converterTempoParaMinutos(value.horasTrabalhadas));
    };

    function findClientId(event, chartContext, config) {
        if (config?.dataPointIndex !== undefined) {
            const clickedIndex = config.dataPointIndex;
            const clickedNome = labels_7.value[clickedIndex];

            const cliente = cargaHoraria.value.find((item) => item.nome === clickedNome);

            if (cliente) {
                openModal(cliente.clienteId);
            }
        }
    }

    const getWeekRange = () => {
        const now = new Date();
        const dayOfWeek = now.getDay(); // 0 (domingo) a 6 (sábado)

        // Encontrar segunda-feira da semana atual
        const monday = new Date(now);
        monday.setDate(now.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

        // Encontrar sexta-feira da mesma semana
        const friday = new Date(monday);
        friday.setDate(monday.getDate() + 4);

        return {
            inicio: monday.toISOString().split('T')[0],
            fim: friday.toISOString().split('T')[0],
        };
    };

    const openModal = async (clienteId) => {
        const { inicio, fim } = getWeekRange();
        let response = null;
        loader.value = true;
        try {
            response = await apiClient.get(`Atividade/GetAtividadesFiltros?pageNumber=${1}&pageSize=${50}&inicio=${inicio}&fim=${fim}&clienteId=${clienteId}&userId=${jwt.userId}`);

            handleApiResponse(response);
        } catch (error) {
            handleApiResponse(error, true);
            return;
        } finally {
            loader.value = false;
        }

        if (!response.data.sucesso) {
            handleApiResponse(response);
            loader.value = false;
            return;
        }

        modalData.value = response.data.objeto;
        modal.show();
    };

    onMounted(() => {
        initPopover();
        getAtividadesHistorico(1, 50);
        getDashboardUsuarioGraficoDaSemana();
        getCargaHoraria();

        const modalElement = document.getElementById('modalHistAtividades');
        modal = new Modal(modalElement);
    });
</script>

<style scoped>
    .bg-dark {
        background-color: #0e1726 !important;
        border-top: 5px solid #191e3a;
    }
</style>
